.team_section  p, .team_section span, .team_section h2, .team_section h3, .team_section h4 {
  color: white !important;
}

#team-member-two, #team-member-three {
  display: none;
}

.team_section_overlay {
  position: absolute;
  z-index: 999;
  background-color: #191919;
  width: 100%;
  height: 100%;
}

#equipe > img {
  position: absolute;
  z-index: 1000;
}

#carousel-container {
  padding-top: 50px;
  padding-bottom: 100px;
}

#team-section {
  z-index: 1000;
}

#team-arrows-container img:first-child {
  left: 0px;
}

#team-arrows-container img:last-child {
  right: 0px;
}

#team-right-section {
  transition: min-height 0.3s, height 0.3s;
}

.arrows_container {
  z-index: 3050;
  height: 100%;
  width: 100%;
}

.arrows_container img {
  cursor: pointer;
  transition: transform 250ms;
}

.arrows_container img:hover {
  transform: scale(1.5);
}

.arrows_container img:active {
  transform: scale(1.25);
}

#carousel {
  position: relative;
  height: 450px;
}

#images-container div {
  overflow: visible;
  position: absolute;
  height: 100%;
  background: url('./img/team/J-M_portraits.jpg') no-repeat;
  background-size: 100% auto;
  background-position: center;
  transition: right 0.5s, width 0.5s, opacity 0.5s;
}

#images-container {
  position: relative;
  width: 350px;
  height: 100%;
  margin: auto;
}

/* PROFILE IMAGE */
.dismiss_profile {
  z-index: 3001;
  width: 80%;
  right: -20%;
  opacity: 0;
}

.first_profile {
  z-index: 3000;
  right: 0%;
  width: 100%;
  opacity: 1;
}

.second_profile {
  z-index: 2999;
  right: 20%;
  width: 90%;
  opacity: 0.75;
}

.third_profile {
  z-index: 2998;
  right: 40%;
  width: 80%;
  opacity: 0.50;
}

.queued_profile {
  z-index: 2997;
  right: 60%;
  width: 70%;
  opacity: 0;
}

/* TEAM MEMBER */
.team_member {
  z-index: 3050;
  position: absolute;
  transition: opacity 250ms;
  bottom: 0;
}

.team_member p {
  font-size: 14px;
  margin-top: 20px;
}

.team_member img {
  height: 30px;
}

.team_member li {
  display: inline-block;
}

/* MEDIA QUERY FOR LARGE TABLETS AND SMALL DESKTOP */
@media only screen and (max-width:992px){
  
  #team-section.container {
    max-width: unset !important;
  }

  #team-arrows-container img {
    width: 75px;
    height: 75px;
  }
}

/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width:768px){
  
  #team-section {
    padding-top: 100px !important;
    margin-top: 0 !important;
  }

  #team-arrows-container img {
    width: 100px;
    height: 100px;
  }

  #carousel-container {
    flex-wrap: wrap;
  }

  .team_member {
    top: 0;
  }
  
  #team-left-section {
    display: block !important;
    margin: auto;
  } 

  #carousel {
    height: 300px;
  }

  #images-container {
    width: 250px;
  }

  #team-right-section {
    margin-top: 30px;
    margin-bottom: 50px;
    min-height: 300px;
    transition: height 250ms;
  }
}

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px) {

  #achievements {
    padding-left: 0;
    padding-right: 0;
  }

  #team-arrows-container {
    left: -45px;
    width: calc(100vw + 30px);
  }

  #team-right-section {
    padding: 0;
  }
}
